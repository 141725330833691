<template>
	<div v-if="apt">
		<v-container fluid class="apt-1 py-5 py-md-12">
			<v-container class="vuetify-container">
				<div class="home-card mb-0 mb-md-5 mb-lg-12 flex-center" data-aos="fade-up" data-aos-duration="1400">
					<v-row class="w-100 ma-0">
						<v-col cols="12" md="6" class="flex-center flex-column pa-0" style="position: relative;">
							<v-btn icon class="btn-left pa-7" dark v-if="apt.images > 1" @click="previousImage(apt.images)">
								<v-icon size="60" color="#ffffff">mdi-chevron-left</v-icon>
							</v-btn>
							<v-btn icon class="btn-right pa-7" dark @click="nextImage(apt.images)" v-if="apt.images > 1">
								<v-icon size="60" color="#ffffff">mdi-chevron-right</v-icon>
							</v-btn>
							<img :src="require('../assets/img/opt/' + apt.slug + '/' + currentImage + '.jpg')" :alt="apt.name" class="apt-cover pointer" v-if="apt.images > 0" @click="openImage">
							<img :src="require('../assets/img/opt/general/1.jpg')" :alt="apt.name" class="apt-cover" v-else @click="openImage">
						</v-col>
						<v-col cols="12" md="6" class="flex-center flex-column px-7 px-lg-12 py-7 py-md-12">
							<h1 class="w-100 dark-text font-weight-bold px-0 px-md-5 px-lg-12 mb-2">
								{{ apt.name }}
							</h1>
							<div class="tags w-100 px-0 px-md-5 px-lg-12 mb-3 mb-md-5">
								<v-btn outlined color="#181818" depressed tile v-for="(item, index) in amenities" :key="index" class="mr-4 mb-4">{{ item }}</v-btn>
							</div>
							<div class="desc w-100 px-0 px-md-5 px-lg-12">
								<div class="flex-center flex-column mb-4">
									<h3 class="w-100 text--primary text-left">{{ apt.desc.title }}</h3>
									<p class="w-100 text--secondary mb-0 text-left">{{ apt.desc.text }}</p>
								</div>
								<div v-for="(item, index) in this.desc" :key="index" class="flex-center flex-column mb-4">
									<h3 class="w-100 text--primary text-left">{{ item.title }}</h3>
									<p class="w-100 text--secondary mb-0 text-left">{{ item.text }}</p>
								</div>
							</div>
							<div class="w-100 px-0 px-md-5 px-lg-12">
								<v-btn depressed tile height="60" width="200" color="#059bda" dark class="font-weight-bold px-5" to="/reserve" link>{{ $t('reserve') }}</v-btn>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-container>
		</v-container>

		<v-dialog v-model="fullImage" fullscreen>
			<v-card class="flex-center flex-column relative" tile>
				<v-btn icon class="btn-left pa-7" dark v-if="apt.images > 1" @click="previousImage(apt.images)">
					<v-icon size="60" color="#ffffff">mdi-chevron-left</v-icon>
				</v-btn>
				<v-btn icon class="btn-right pa-7" dark @click="nextImage(apt.images)" v-if="apt.images > 1">
					<v-icon size="60" color="#ffffff">mdi-chevron-right</v-icon>
				</v-btn>
				<v-btn icon @click="fullImage = false" style="position: absolute; right: 20px; top: 20px;">
					<v-icon x-large>mdi-close</v-icon>
				</v-btn>
				<img :src="require('../assets/img/opt/' + apt.slug + '/' + currentImage + '.jpg')" :alt="apt.name" v-if="apt.images > 0" style="max-width: 100%; height: auto;">
				<img :src="require('../assets/img/opt/general/1.jpg')" :alt="apt.name" v-else style="max-width: 100%; height: auto;">
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
export default {
	name: 'Apartment',
	data: () => ({
		apt: null,
		amenities: null,
		desc: null,
		images: [],
		currentImage: 1,
		fullImage: false,
		imgSrc: null,
	}),
	watch: {
		'$route.params.slug': {
			handler(newSlug) {
				this.initApt(newSlug);
				this.currentImage = 1;
			},
			immediate: true
		}	
	},
	methods: {
		openImage: function(e) {
			this.fullImage = true;
		},
		nextImage(imageNum) {
			if(this.currentImage < imageNum)
				this.currentImage++
			else
				this.currentImage = 1
		},
		previousImage(imageNum) {
			if(this.currentImage > 1)
				this.currentImage--
			else
				this.currentImage = imageNum
		},
		initApt: function(slug) {
			this.amenities = this.$store.getters.apartments.amenities
			this.desc = this.$store.getters.apartments.desc

			let apt = this.$store.getters.apartments.items.find(item => item.slug === slug)
			if(apt)
				this.apt = apt
			else
				this.$router.push('/404')

			let images = []
			for(var i = 1; i<= apt.images; i++) {
				images.push(i + '.jpg');
			}

			this.images = images;
		}
	}
}
</script>
<style lang="scss">
.apt-1 {
	min-height: calc(100vh - 90px);
	background-color: #eee;
}
.home-card {
	background-color: #fff;
	.row {
		min-height: 60vh;
	}
}
.apt-cover {
	max-width: 100%;
	object-fit: cover;
	height: 100%;
	max-height: 100%;
	cursor: pointer;
}
.btn-left, .btn-right {
	position: absolute;
	background-color: rgba(0, 0, 0, .4) !important;
}
.btn-left {
	position: absolute;
	left: 5%;
}
.btn-right {
	position: absolute;
	right: 5%;
}
@media(max-width: 950px) {
	.apt-cover {
		max-height: 300px !important;
	}
}
</style>